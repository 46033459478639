export const DESCRIPTIONS = {
  HOMEPAGE:
    "Need to grow and expand your business? Open a Moniepoint account today and get access to quick loans, easy payment collection,  and so much more.",
  PAYMENTS:
    "With Moniepoint, you can enjoy multiple options to accept payments from your customers. Accept payments easily from your customers via POS, transfer, or USSD.",
  LOANS:
    "Never let a lack of funds limit your business. Get the funding your business needs to grow and expand your business. Get quick loans for your business",
  MANAGE:
    "Open a Moniepoint account today and empower your business with tools that track your expenses, automate payroll, taxes and monitor employee performance.",
  PROTECT:
    "With Moniepoint, you can enjoy total protection from the financial impact of risks that occur while running your business",
  CONTACTUS:
    "Get instant solutions to all your business needs. 24/7 Support. Speak to an expert.",
  CAREERS:
    "Explore life-changing career opportunities with Moniepoint. Learn more about our career opportunities and talent programmes. Explore careers",
  ABOUTUS: "Discover the values we hold dear to us that have shaped us into being the bank you trust"
};

export const Nigeria_MetaTags = {
  homepage: {
    title: "Simple solutions to power your business | Moniepoint Nigeria",
    description: "Need to grow and expand your business? Open a Moniepoint account today and get access to quick loans, easy payment collection, and so much more."
  },
  personalPage: {
    title: "Simple solutions to power your business | Moniepoint Nigeria",
    description: "Need to grow and expand your business? Open a Moniepoint account today and get access to quick loans, easy payment collection, and so much more."
  },
  salaryAdvance: {
    title: "Simple solutions to power your business | Moniepoint Nigeria",
    description: "Need to grow and expand your business? Open a Moniepoint account today and get access to quick loans, easy payment collection, and so much more."
  },
  businessAccount: {
    title: "Business Account - Open A Business Bank Account in 4 Easy Steps | Moniepoint Nigeria",
    description: "Get Moniepoint MFB business account for your everyday business banking needs. Open a business bank account online in 4 easy steps"
  },
  pos: {
    title: "POS Terminals - Point of Sale Terminals for Small Businesses | Moniepoint Nigeria",
    description: "Moniepoint POS terminals are perfect for businesses of all types and sizes.  Let your customers to pay at the ✓  register, ✓  checkout line or throughout the store. Get one today ✓ "
  },
  credit: {
    title: "Small Business Loans - SME Loans & Working Capital | Moniepoint Nigeria",
    description: "Need a business loan? Moniepoint offer a variety of loans for businesses of all sizes and stages. Apply now and get the funding you need to grow your business!"
  },
  expenseCards: {
    title: "Expense Cards - Business Expenses Card | Moniepoint Nigeria",
    description: "Looking for a way to streamline your company's expenses? Moniepoint offers corporate expense cards that make it easy to track and manage spending. Our cards are accepted at millions of locations worldwide, so you can be sure your employees will have no trouble using them when they're on the"
  },
  cards: {
    title: "Cards",
    description: ""
  },
  payment: {
    title: "Payment",
    description: ""
  },
  personalBanking: {
    homepage: {
      title: "Personal Banking - Open A Personal Bank Account Online | Moniepoint",
      description: "Moniepoint offers a wide range of personal banking services including savings accounts, debit cards, personal loans and much more. Open a personal bank account today."
    },
    banking: {
      title: "Savings Account - Open A Savings Account Online | Moniepoint",
      description: "Open a Moniepoint Personal Savings Account today and reach your goal while enjoying unparalleled banking services and high interest rates"
    },
    payment: {
      title: "Payment - Pay Utility Bills, Buy Data and Airtime Online | Moniepoint",
      description: "Make payment easily and securely. Moniepoint payment solutions offer you a platform to pay utility bills, buy data or airtime, male transfer and much more securely and faster"
    },
    salaryAdvance: {
      title: "Payday Loans - Get A Salary Advance Loan Today | Moniepoint",
      description: "Moniepoint makes it easy to get payday loans. Get a salary advance when you need it. No collateral needed. Apply now!"
    },
    debitCards: {
      title: "Debit Cards - Get A Moniepoint Card Today | Moniepoint",
      description: "Need to make payment online or in person? Get a Moniepoint debit card today and enjoy rewards and exciting benefits. Apply now."
    },
    bbnGame: {
      title: "BBN Game - Over 200m to be won with Moniepoint ",
      description: "Open a personal bank account, and earn weekly while doing tasks this BBN Season."
    },

  }

}




export const MONIEPOINT_ONBOARDING = "https://onboarding.mab.console.teamapt.com";

export const REGEX = {
   PHONENUMBER: '^[0]+[7-9]+[0-1]+[0-9]{8}$',
}
// export const RECAPTCHA_SITE_KEY = '6Ld6zqwhAAAAAAgmy80-Y5CEGa8ABREsT1jun_dW';
export const RECAPTCHA_SITE_KEY = '6Le-wvkSAAAAAPBMRTvw0Q4Muexq9bi0DJwx_mJ-';

export const RECAPTCHA_URL = 'https://www.google.com/recaptcha/api.js';
