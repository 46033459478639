import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/layout";
import styled from "styled-components";
import { Link, Trans, useI18next } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { getJobById, parseGreenHousJobContent, post } from "../../helpers/functions";
import UploadIcon from "../../images/inside-moniepoint/upload-icon.png";
import UploadSuccessIcon from "../../images/inside-moniepoint/upload-success-icon.png";
import UploadErrorIcon from "../../images/inside-moniepoint/upload-error-icon.png";
import SuccessIcon from "../../images/inside-moniepoint/success-icon.svg";
import { TrashIcon } from "../../images/Icons";
import { useFormik } from "formik";
import { object, string, mixed, url } from "yup";
import { RECAPTCHA_SITE_KEY, REGEX } from "../../helpers/constants";
import Reaptcha from "reaptcha";
import { Dropdown } from "react-bootstrap";
import { countries } from "../../helpers/countries";
import useLocation from "../../hooks/useLocation";
import axios from "axios";

const JobDescriptionPage = ({ location }) => {
  const { navigate } = useI18next();
  const [jobId, setJobId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [job, setJob] = React.useState();

  const [applicationState, setApplicationState] = useState("PREAPPLICATION");

  const startApplication = () => {
    setApplicationState("APPLYING");
    window.scrollTo(0, 0);
  };
  const [verified, setVerified] = React.useState(true);
  const [code, setCode] = React.useState("");

  const captchaRef = React.useRef(null);
  const verifyCallback = (response) => {
    if (response) {
      setVerified(true);
    }
  };

  const Schema = object({
    first_name: string().required("First Name is required"),
    last_name: string().required("Last Name is required"),
    email: string()
      .required("Email Address is required")
      .email("Invalid email address"),
    phone: string()
      .required("Phone Number is required")
      .matches(REGEX.PHONENUMBER, "Invalid phone number"),
    resume: object({
      content: string().required("Resume/CV  is required"),
      content_filename: string().required("Resume/CV  is required"),
    }),
    cover_letter: object({
      content: string().optional(),
      content_filename: string().optional(),
    }),
    question_5555: string().url("Invalid url").optional(),
  });

  const onSubmit = (values) => {
    const data = { ...values, phone: code + values.phone };
    submitApplication(data);
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      resume: null,
      cover_letter: null,
      question_5555: "",
    },
    validationSchema: Schema,
    onSubmit: onSubmit,
  });

  const handleCodeChage = (code) => {
    setCode(code);
  };

  function submitApplication(value) {
    const baseURL = `https://boards-api.greenhouse.io/v1/boards/moniepoint/jobs/${jobId}`;
    const config = {
      // adapter: jsonpAdapter,
      adapter: ['curl'],
      mode: 'cors',
      credentials: "omit",
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        'Authorization': 'Basic N2JlNjBkNTZkNTgzZmE0ZDlkNDU4ODdkNzRkN2Q1NmYtMTAxOg=='
      }
    }
    const form = {
      first_name: value.first_name,
      last_name: value.last_name,
      email: value.email,
      phone: value.phone,
      question_5555: value.question_5555,
      resume_content: value.resume.content,
      resume_content_filename: value.resume.content_filename,
      cover_letter_content: value.resume.content,
      cover_letter_content_filename: value.resume.content_filename,
    };
    try {
      axios.post(baseURL, form, config).then((response) => {
        console.log(response);
        if (response.status === 201) {
          setApplicationState("SUBMITTED");
        }
      });

    } catch (error) {
      console.log(error);
    }
    // jsonp(`${baseURL}?callback=${JSON.stringify(form)}`, {
    //   param: 'callback',
    //   prefix: 'callback',
    //   method: 'POST',
    //   data: form
    // }, (err, data) => {
    //   if (err) {
    //     console.error(err.message);
    //   } else {
    //     console.log(data);
    //   }
    // });
  }

  useEffect(() => {
    async function fetctJobDetails() {
      setLoading(true);
      const job_id = location.search.split("=").pop();
      if (!job_id) {
        navigate("/404");
      }
      setJobId(job_id);
      await getJobById(job_id).then((response) => {
        const jobDetails = response.data;
        console.log(jobDetails)
        setJob({ ...jobDetails, location: jobDetails.location.name });
      }).catch((error) => {
        navigate("/404");
      })
      setLoading(false);
    }
    fetctJobDetails();
  }, []);

  return (
    <Layout pageTitle={`${job?.title} - Moniepoint`}>
      {!loading && (
        <JobDescriptionContainer>
          <div className="content">
            <h3 className="title">
              {applicationState !== "PREAPPLICATION" && (
                <Trans>you_are_applying_for</Trans>
              )}{" "}
              {job?.title}
            </h3>
            <h6 className="subtitle">Location: {job?.location}</h6>
            {applicationState === "PREAPPLICATION" && (
              <a href={`https://boards.eu.greenhouse.io/moniepoint/jobs/${jobId}#app`} target="_blank" className="btn btn-primary">
                <Trans>job_cta</Trans>
              </a>
            )}

            {applicationState === "PREAPPLICATION" && (
              <div className="content-container">
                <div
                  className="content-text"
                  dangerouslySetInnerHTML={{
                    __html: parseGreenHousJobContent(job?.content),
                  }}
                ></div>
                <a href={`https://boards.eu.greenhouse.io/moniepoint/jobs/${jobId}#app`} target="_blank" className="btn btn-primary">
                  <Trans>job_cta</Trans>
                </a>
              </div>
            )}

            {applicationState === "APPLYING" && (
              <form
                className="application-container"
                onSubmit={formik.handleSubmit}
              >
                <div className="form-card">
                  <div className="title">
                    <Trans>general_information</Trans> <span>*</span>
                  </div>
                  <div className="input-row">
                    <TextInputComponent
                      type="text"
                      placeholder="First Name"
                      required
                      {...formik.getFieldProps("first_name")}
                      error={
                        formik.errors.first_name &&
                        formik.touched.first_name &&
                        formik.errors.first_name
                      }
                    />
                    <TextInputComponent
                      type="text"
                      placeholder="Last Name"
                      required
                      {...formik.getFieldProps("last_name")}
                      error={
                        formik.errors.last_name &&
                        formik.touched.last_name &&
                        formik.errors.last_name
                      }
                    />
                  </div>
                  <div className="input-row">
                    <TextInputComponent
                      type="email"
                      placeholder="Email Address"
                      {...formik.getFieldProps("email")}
                      error={
                        formik.errors.email &&
                        formik.touched.email &&
                        formik.errors.email
                      }
                    />
                  </div>

                  <div className="input-row">
                    <PhoneNumberInputComponent
                      placeholder="Phone Number"
                      onCodeChange={handleCodeChage}
                      {...formik.getFieldProps("phone")}
                      maxLength={11}
                      error={
                        formik.errors.phone &&
                        formik.touched.phone &&
                        formik.errors.phone
                      }
                    />
                  </div>
                </div>
                <div className="form-card">
                  <div className="title">
                    <Trans>your_work</Trans>
                  </div>
                  <FileUploadComponent
                    name="resume"
                    label={"Upload CV/Resume"}
                    {...formik.getFieldProps("resume")}
                    formError={
                      formik.errors.resume &&
                      formik.touched.resume &&
                      formik.errors.resume
                    }
                  />
                  <FileUploadComponent label={"Upload Cover Letter"} name="cover_letter"  {...formik.getFieldProps("cover_letter")} />
                  <div className="input-row">
                    <TextInputComponent
                      type="url"
                      placeholder="Link to LinkedIn profile"
                      {...formik.getFieldProps("question_5555")}
                      error={
                        formik.errors.question_5555 &&
                        formik.touched.question_5555 &&
                        formik.errors.question_5555
                      }
                    />
                  </div>
                  <div className="recaptcha">
                    <Reaptcha
                      ref={captchaRef}
                      sitekey={RECAPTCHA_SITE_KEY}
                      onVerify={verifyCallback}
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={!formik.isValid || !verified}
                  className="btn btn-primary"
                >
                  <Trans>submit_application</Trans>
                </button>
              </form>
            )}

            {applicationState === "SUBMITTED" && (
              <>
                <div className="success-card">
                  <img src={SuccessIcon} alt="success-icon" />
                  <div className="success-title">
                    <Trans>application_successful</Trans>
                  </div>
                  <div className="success-description">
                    <Trans>application_successful_description</Trans>
                  </div>
                </div>
                <Link to="/inside-moniepoint" className="btn btn-primary">
                  <Trans>Return to website</Trans>
                </Link>
              </>
            )}
          </div>
        </JobDescriptionContainer>
      )}
    </Layout>
  );
};

export default JobDescriptionPage;

const FileUploadComponent = ({
  label,
  name,
  id,
  required = false,
  accept = "application/pdf, .doc, .docx",
  onChange,
  formError,
  props,
}) => {
  const [fileName, setFileName] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const fileSize = 2048000;

  const clearFile = () => {
    setError(false);
    setSuccess(false);
    onChange({
      target: {
        name: name,
        value: "",
      },
    });
  };

  const handleChange = (event) => {
    setError(false);
    setSuccess(false);
    let file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (evt) {
        const metadata = `name: ${file.name}, type: ${file.type}, size: ${file.size}, contents:`;
        const contents = evt.target.result;
        setFileName(file.name);
        if (file.size > fileSize || file.size === 0) {
          setError(true);
          onChange({
            target: {
              name: event.target.name,
              value: "",
            },
          });
          return;
        }
        if (onChange)
          onChange({
            target: {
              name: event.target.name,
              value: {
                content: contents,
                content_filename: file.name,
              },
            },
          });
        setSuccess(true);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <FileUploadContainer>
      <label htmlFor="">
        <Trans>{label}</Trans> {required && <span>*</span>}
      </label>
      <div
        className={`uploader-container ${success && "success"} ${error && "error"
          }`}
      >
        <input
          type="file"
          name={name}
          id={id}
          required={required}
          accept={accept}
          onChange={handleChange}
        />
        {!success && !error && (
          <div className="uploader-content">
            <img
              width="73.22px"
              style={{ marginRight: "24px" }}
              src={UploadIcon}
              alt="..."
              className=""
            />

            <div className="text-section">
              <div className="title">
                <Trans>Click to Upload Document</Trans>
              </div>
              <div className="description">
                <span> <Trans>PDF, DOC, DOCX</Trans></span>
                <span>
                  <Trans>Max Filesize</Trans>: 2MB
                </span>
              </div>
            </div>
          </div>
        )}
        {(success || error) && (
          <div className="uploader-content">
            {error && (
              <img width="73.22px" src={UploadErrorIcon} alt="..." className="" />
            )}
            {success && (
              <img
                width="73.22px"
                src={UploadSuccessIcon}
                alt="..."
                className=""
              />
            )}
            <div className="text-section">
              <div className="title">
                {error && <Trans>Upload Failed</Trans>}
                {success && <Trans>Click to Upload Document</Trans>}
              </div>
              <div className="description">{fileName}</div>
            </div>
            <div className="action" onClick={clearFile}>
              {success && <TrashIcon />}
              {error && <span className="error">Retry</span>}
            </div>
          </div>
        )}
      </div>
      {formError && <p className="error-message">{formError}</p>}
    </FileUploadContainer>
  );
};

const TextInputComponent = (props) => {
  const [focus, setFocus] = useState(false);
  function handleFocus(event) {
    setFocus(!focus);
    if (props.onFocus) {
      props.onFocus(event);
    }
  }
  function handleBlur(event) {
    setFocus(!focus);
    if (props.onBlur) {
      props.onBlur(event);
    }
  }

  const inputEl = useRef(null);
  const onButtonClick = () => {
    inputEl.current.focus();
  };

  return (
    <TextInputContainer>
      <div className={`input-container ${props?.error && "error"}`}>
        <label
          className={`input-label ${(focus || props.value?.toString()?.length > 0) && "focused"
            }`}
          onClick={onButtonClick}
        >
          {props.label || props.placeholder}
        </label>
        <input
          ref={inputEl}
          type={props.type}
          placeholder={props.placeholder}
          name={props.name}
          className={`${(focus || props.value?.toString()?.length > 0) && "focused"
            }`}
          {...props}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
      {props.error && <small className="error-message">{props.error}</small>}
    </TextInputContainer>
  );
};

const PhoneNumberInputComponent = (props) => {
  const { countryName } = useLocation("Nigeria");
  const [focus, setFocus] = useState(false);
  const [country, setCountry] = useState(
    countries.find((country) => country.name === countryName)
  );

  function handleFocus(event) {
    setFocus(!focus);
    if (props.onFocus) {
      props.onFocus(event);
    }
  }
  function handleBlur(event) {
    setFocus(!focus);
    if (props.onBlur) {
      props.onBlur(event);
    }
  }

  const inputEl = useRef(null);
  const onButtonClick = () => {
    inputEl.current.focus();
  };

  const handleChange = (event) => {
    if (props.onChange) {
      props.onChange(event);
    }
    if (props.onCodeChange) {
      props.onCodeChange(country.dial_code);
    }
  };

  useEffect(() => {
    if (props.onCodeChange) {
      props.onCodeChange(country.dial_code);
    }
  }, [country]);

  return (
    <PhoneNumberInputContainer>
      <div className={`input-container ${props?.error && "error"}`}>
        <Dropdown as="div" className="country-code-container">
          <Dropdown.Toggle id="dropdown-basic" as="div">
            <span>{country?.flag}</span>{" "}
            <span className="name">{country?.dial_code}</span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {countries.map((country, i) => (
              <Dropdown.Item
                key={i}
                as="div"
                onClick={() => setCountry(country)}
              >
                <span className="flag">{country.flag}</span>
                <span className="name">{country.name}</span>
                <span className="code">{country.dial_code}</span>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <div className="right-container">
          <label
            className={`input-label ${(focus || props.value?.toString()?.length > 0) && "focused"
              }`}
            onClick={onButtonClick}
          >
            {props.label || props.placeholder}
          </label>
          <input
            ref={inputEl}
            type={props.type}
            placeholder={props.placeholder}
            name={props.name}
            className={`${(focus || props.value?.toString()?.length > 0) && "focused"
              }`}
            {...props}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </div>
      </div>
      {props.error && <small className="error-message">{props.error}</small>}
    </PhoneNumberInputContainer>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const JobDescriptionContainer = styled.section`
  background: rgba(247, 250, 254, 1);

  .content {
    padding: 72px 150px 114px;
    max-width: 1440px;
    margin: auto;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 72px 60px 114px;
    }

    .title {
      font-family: "Founders Grotesk";
      font-size: 36px;
      font-weight: 500;
      line-height: 42px;
      letter-spacing: 0em;
      text-align: center;
      margin-bottom: 8px;
    }
    .subtitle {
      font-family: "Founders Grotesk";
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: center;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 16px;
    }

    .btn.btn-primary {
      margin: 0 auto 48px;
    }

    .content-container {
      background-color: white;
      border-radius: 8px;
      width: 100%;
      padding: 40px;
      font-size: 14px;
      font-weight: 500;
      line-height: 23px;
      letter-spacing: 0em;
      text-align: left;

      .content-text {
        * > strong {
          font-family: "Founders Grotesk";
          font-size: 20px;
          font-weight: 500;
          line-height: 26px;
          letter-spacing: 0em;
          text-align: left;
          margin-bottom: 16px;
        }

        ul {
          padding-left: 1rem;
        }

        p:has(span) {
          margin-bottom: 8px;
        }
      }

      p:last-child {
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
      }

      .btn.btn-primary {
        margin: 0 auto;
      }
    }

    .application-container {
      width: 100%;
      margin-top: 32px;

      .form-card {
        max-width: 576px;
        margin: 0 auto 24px;
        background-color: white;
        padding: 32px 40px 48px;
        border-radius: 8px;

        .title {
          font-family: "Founders Grotesk";
          font-size: 20px;
          font-weight: 500;
          line-height: 26px;
          letter-spacing: 0em;
          text-align: left;
          margin-bottom: 24px;
          color: rgba(0, 0, 0, 1);
          span {
            color: rgba(233, 68, 68, 1);
          }
        }

        .input-row {
          display: flex;
          align-items: flex-start;
          gap: 16px;
          justify-content: space-between;
          margin-bottom: 16px;
        }

        & > .input-row:last-child {
          margin-bottom: 0px;
        }

        .recaptcha {
          display: flex;

          & > div {
            margin: auto;
          }
        }

        .custom-input {
          all: unset;
          max-width: 496px;
          max-height: 56px;
          width: 100%;
          border: 0.7px solid rgba(11, 50, 117, 0.3);
          border-radius: 4px;
          padding: 18px 16px;
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          color: rgba(0, 0, 0, 1);

          &::placeholder {
            color: rgba(0, 0, 0, 0.29);
          }
        }
      }
    }

    .success-card {
      background-color: white;
      max-width: 493px;
      margin: 88px auto 83px;
      border-radius: 8px;
      padding: 64px 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .success-icon {
        width: 140px;
        height: auto;
      }

      .success-title {
        font-family: "Founders Grotesk";
        font-size: 24px;
        font-weight: 500;
        line-height: 31px;
        letter-spacing: 0em;
        color: black;
        margin: 32px 0 16px;
      }

      .success-description {
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
        color: black;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 38.21px 20px 60px;

      .title {
        font-size: 32px;
        line-height: 37.76px;
      }
      .subtitle {
        font-size: 20px;
        line-height: 23.6px;
      }

      .input-row {
        flex-direction: column;
      }

      .btn.btn-primary {
        margin: 0 auto 24px;
      }

      .content-container {
        padding: 24px 20px;

        .content-text {
          margin-bottom: 48px;
        }
      }

      .success-card {
        padding: 48px 20px;
        margin: 52px auto 32px;

        .success-icon {
          width: 78.75px;
        }
      }
    }
  }
`;

const FileUploadContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 22px 18px;
  margin-bottom: 24px;

  label {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 10px;

    span {
      color: rgba(233, 68, 68, 1);
    }
  }

  .uploader-container {
    position: relative;
    border: 1px dashed rgba(3, 87, 238, 0.4);
    border-radius: 16px;
    background: rgba(247, 250, 254, 1);
    cursor: pointer;

    &.error {
      border: 1px dashed rgba(233, 68, 68, 0.9);
      background: rgba(233, 68, 68, 0.05);

      .uploader-content .text-section {
        .title {
          color: rgba(233, 68, 68, 1);
          margin-bottom: 4px;
        }
        .description {
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }
    &.success {
      border: 1px dashed rgba(30, 177, 45, 0.9);
      background: rgba(30, 177, 45, 0.05);

      .uploader-content .text-section {
        .title {
          color: rgba(30, 177, 45, 1);
          margin-bottom: 4px;
        }
        .description {
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }

    input[type="file"] {
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 0;
    }

    .uploader-content {
      display: flex;
      align-items: center;
      padding: 16px;
      gap: 24px;
      @media (max-width: 768px) {
            gap: 0;
          }

      .text-section {
        .title {
          font-family: "Inter";
          font-size: 12px;
          font-weight: 500;
          line-height: 15px;
          letter-spacing: 0em;
          text-align: left;
          margin-bottom: 0px;
        }
        .description {
          font-size: 11px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.025em;
          text-align: left;
          color: rgba(46, 56, 77, 0.5);
          display: flex;
          align-items: center;
          gap: 16px;
          word-break: break-all;

          @media (max-width: 768px) {
            flex-direction: column;
            gap: 0;
            align-items: start;
          }

          &.uploading {
            color: rgba(0, 0, 0, 1);
          }
        }

        .progress-container {
          height: 8px;
          width: 222px;
          background: rgba(3, 87, 238, 0.12);
          border-radius: 13px;
          margin-bottom: 4px;
          display: flex;
          align-items: center;

          .progress {
            height: 8px;
            border-radius: 13px;
            background: #0361f0;
            transition: width 0.2s ease-in-out;
          }
        }

        .upload-level {
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
          letter-spacing: -0.025em;
          text-align: left;
          color: rgba(11, 50, 117, 0.45);
          margin-bottom: 4px;
        }
      }

      .action {
        margin-left: auto;
        position: relative;

        span.error {
          font-size: 12px;
          font-weight: 700;
          line-height: 17px;
          letter-spacing: 0em;
          text-align: center;
          color: rgba(233, 68, 68, 1);
        }
      }
    }
  }

  .error-message {
    margin: 4px 0 0;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(233, 68, 68, 1);
  }
`;

const TextInputContainer = styled.div`
  width: 100%;

  .input-container {
    position: relative;
    background: rgba(255, 255, 255, 1);
    display: flex;
    border: 0.7px solid rgba(11, 50, 117, 0.3);
    border-radius: 4px;

    &.error {
      border: 1px solid rgba(233, 68, 68, 1);

      .input-label {
        color: rgba(233, 68, 68, 1);
      }
    }
  }

  .input-label {
    position: absolute;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    left: 16px;
    top: 18px;
    color: rgba(0, 0, 0, 0.29);
    transition: all 0.2s ease-in-out;
    cursor: auto;

    &.focused {
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0.4000000059604645px;
      text-align: left;
      top: 8px;
    }
  }

  input {
    all: unset;
    width: 100%;
    padding: 18px 16px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    border-radius: 4px;

    &:autofill,
    &:-internal-autofill-selected {
      background-color: white !important;
    }

    &.focused {
      padding: 22px 16px 14px;
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0);
    }
  }

  .error-message {
    margin: 4px 0 0;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(233, 68, 68, 1);
  }
`;

const PhoneNumberInputContainer = styled.div`
  width: 100%;

  .input-container {
    position: relative;
    background: rgba(255, 255, 255, 1);
    display: flex;
    border: 0.7px solid rgba(11, 50, 117, 0.3);
    border-radius: 4px;
    width: 100%;

    &.error {
      border: 1px solid rgba(233, 68, 68, 1);

      .input-label {
        color: rgba(233, 68, 68, 1);
      }
      .country-code-container {
        border-right: 1px solid rgba(233, 68, 68, 1);
      }
    }

    .country-code-container {
      width: 90px;

      border-right: 0.7px solid rgba(11, 50, 117, 0.3);

      .dropdown-toggle {
        width: 82px;
        padding: 18px 14px;
        display: flex;
        align-items: center;
        gap: 8px;

        &:after {
          display: none;
        }

        span.name {
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.0009em;
          text-align: left;
          color: rgba(0, 0, 0, 0.7);
        }
      }

      .dropdown-menu {
        border: none;
        box-shadow: 0px 15px 17px rgba(0, 41, 114, 0.15);
        border-radius: 8px;
        height: 200px;
        overflow: scroll;

        .dropdown-item {
          display: flex;
          align-items: center;
          padding: 8px 14px;
          gap: 8px;
          cursor: pointer;

          &.selected,
          &:hover {
            background-color: rgba(3, 87, 238, 0.05);
          }

          span.flag {
            font-size: 20px;
            line-height: 1;
          }

          span.code {
            font-weight: 600;
            font-size: 13px;
            line-height: 1;
          }

          span.name {
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.0009em;
            text-align: left;
          }
        }
      }
    }

    .right-container {
      width: 100%;
      position: relative;
      display: flex;
      background: rgba(255, 255, 255, 1);
      border-radius: 16px;
    }
  }

  .input-label {
    position: absolute;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    left: 16px;
    top: 18px;
    color: rgba(0, 0, 0, 0.29);
    transition: all 0.2s ease-in-out;
    cursor: auto;

    &.focused {
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0.4000000059604645px;
      text-align: left;
      top: 8px;
    }
  }

  input {
    all: unset;
    width: 100%;
    padding: 18px 16px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    border-radius: 0 4px 4px 0;

    &:autofill,
    &:-internal-autofill-selected {
      background-color: white !important;
    }

    &.focused {
      padding: 22px 16px 14px;
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0);
    }
  }

  .error-message {
    margin: 4px 0 0;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(233, 68, 68, 1);
  }
`;
